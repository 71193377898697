import { init, captureException, ErrorBoundary, getCurrentScope } from "@sentry/react";
export type { FallbackRender } from "@sentry/react";
import { ErrorEvent } from "@sentry/core";
import { captureConsoleIntegration, setUser } from "@sentry/browser";
import analytics from "../analytics";


// Taken from the Sentry SDK internals
function getPossibleEventMessages(event: ErrorEvent) {
  if (event.message) {
    return [event.message];
  }
  if (event.exception) {
    const { values } = event.exception;
    try {
      const { type = '', value = '' } = (values && values[values.length - 1]) || {};
      return [`${value}`, `${type}: ${value}`];
    } catch (oO) {
      return [];
    }
  }
  return [];
}


if (process.env.NODE_ENV === 'production') {
  init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: process.env.REACT_APP_RELEASE_NAME,
    ignoreErrors: [
      /Can't find variable: (al_onAdViewRendered|al_onPoststitialDismiss)/, // Weird errors from Twitter in-app browser
      'L', // Error from gapi javascript (ie Google, b/c adblock?)
      /Non-Error promise rejection captured with value: Object Not Found Matching Id:\d/, // UnhandledRejection error by Microsoft 365 email scanner
    ],
    integrations: [
      captureConsoleIntegration({ levels: ['error', 'warn'] })
    ],
    beforeSend(event: ErrorEvent) {
      const messages = getPossibleEventMessages(event);
      if (event.user?.email) {
        if(event.user.email.match(/([.+^]test@|example.com$)/)) {
          if(messages.some(message => message.match(/(Failed to fetch|YouTube player|branch.initError)/))) {
            // Drop event
            return null;
          }
        }
      }
      return event;
    },
  });

  const scope = getCurrentScope();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scope.addEventProcessor(function (event, hint) {
    analytics.track('error', {
      category: 'sentry',
      error: event.message,
    })
    return event;
  });
}

export { captureException, ErrorBoundary, setUser };
