import { ApolloClient, from, split } from '@apollo/client';
import { getMainDefinition } from "@apollo/client/utilities";
import { onError } from "@apollo/client/link/error";
import { setContext } from "@apollo/client/link/context";
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { RetryLink } from "@apollo/client/link/retry";
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { persistCache, LocalStorageWrapper } from 'apollo3-cache-persist';
import { SentryLink } from 'apollo-link-sentry';
import isPlainObject from 'lodash/isPlainObject';
import { getIdToken } from 'firebase/auth';
import { Device } from '@capacitor/device';
import { v4 as uuidv4 } from 'uuid';

import { localStorageAvailable } from "../utils/detect";
import { resolvers, typeDefs } from '../resolvers';
import { firebaseAuth, signOut } from '../firebase';
import { createCache } from '../cache';


let activeSocket, timedOut;
const wsLink = new GraphQLWsLink(createClient({
  url: process.env.REACT_APP_GRAPHQL_URL?.replace(/^http(s?):\/\/(.*)\/graphql/, 'ws$1://$2/subscriptions'),  // ws://localhost:8080/subscriptions
  lazy: true,
  reconnect: true,
  shouldRetry: () => true,
  retryAttempts: 10_000,
  keepAlive: 10_000,
  connectionParams: async () => {
    return new Promise((resolve, reject) => {
      const abortTimer = setTimeout(() => reject(new Error('Timeout trying to fetch user for subscription auth token')), 60_000);
      const pollAuth = () => {
        if(firebaseAuth.currentUser && firebaseAuth.currentUser?.email) {
          clearTimeout(abortTimer);
          getIdToken(firebaseAuth.currentUser).then(idToken => resolve(idToken));
        } else {
          setTimeout(pollAuth, 100);
        }
      };
      pollAuth();
    }).then(token => {
      return { authorization: token };
    });
  },
  on: {
    connected: (socket) => (activeSocket = socket),
    closed: () => (activeSocket = null),
    ping: (received) => {
      if (!received) { // sent
        timedOut = setTimeout(() => {
          if (activeSocket?.readyState === WebSocket.OPEN) {
            activeSocket.close(4408, 'Request Timeout');
          }
        }, 10_000); // wait 10 seconds for the pong and then close the connection
      }
    },
    pong: (received) => {
      if (received) {
        // pong is received, clear connection close timeout
        clearTimeout(timedOut);
      }
    },
  },
}));


const batchLink = new BatchHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  credentials: 'include',
  batchMax: 1, // test disable batching
  batchInterval: 1, // only batch within same tick
});


const isFile = (value) => {
  if (isPlainObject(value)) {
    return Object.values(value).map(isFile).includes(true);
  }
  const isfile = typeof File !== 'undefined' && value instanceof File;
  const isblob = typeof Blob !== 'undefined' && value instanceof Blob;
  return isfile || isblob;
}


const isUpload = ({ variables }) => {
  return Object.values(variables).some(isFile);
}


const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_GRAPHQL_URL,
  credentials: 'include',
});


const authLink = setContext((request, previousContext) => {
  const user = firebaseAuth.currentUser;
  if(user) {
    return user.getIdToken().then((token) => {
      return {
        ...previousContext,
        headers: {
          ...previousContext.headers,
          'Authorization': `Bearer ${token}`,
        },
      };
    });
  } else {
    return previousContext;
  }
});


const headerLink = setContext((request, previousContext) => {
  const context = { ...previousContext, headers: { ...previousContext.headers } };

  context.headers['X-Request-Id'] = uuidv4();

  return Device.getId().then(({ identifier }) => {
    context.headers['X-Device-Id'] = identifier;
    return context;
  }).catch(err => {
    if(localStorageAvailable) {
      console.error('Error trying to load device id', err);
    }
    return context;
  })
});




const errorLink = onError(({ graphQLErrors, networkError, response, operation }) => {
  if(!response) {
    // simplifies code below, preventing the need to check if response is null
    response = {};
  }

  if (operation.operationName === "IgnoreErrorsQuery") {
    response.errors = null;
  }

  if (graphQLErrors) {
    graphQLErrors.map((err) => {
      const { message, locations, path, code, extensions } = err;

      if(message === 'User account has been deleted') {
        signOut();
        response.errors = null;
        return;
      }

      if(err.extensions?.code === 'UNAUTHENTICATED') {
        response.data = null;
        response.errors = null;
      }
      console.log(`[GraphQL error]:
        Message: ${JSON.stringify(message)},
        Location: ${JSON.stringify(locations)},
        Path: ${JSON.stringify(path)},
        Code: ${JSON.stringify(code)},
        Extensions: ${JSON.stringify(extensions)},
      `);
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${JSON.stringify(networkError)}`);
  }
});


const sentryLink = new SentryLink();


const retryLink = new RetryLink({
  delay: {
    initial: 500,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: 7,
    retryIf: (error) => !!error
  }
});


const terminalLink = split(isUpload, uploadLink, batchLink)


let link = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  from([errorLink, sentryLink, headerLink, authLink, retryLink, terminalLink])
);


const cache = createCache();


export async function getClient() {

  // guard for browsers without localStorage, ie twitter in app browser/webview
  if(localStorageAvailable) {
    // await before instantiating ApolloClient, else queries might run before the cache is persisted
    await persistCache({
      cache,
      storage: new LocalStorageWrapper(window.localStorage),
    });
  }

  const hydratedCache = window.__APOLLO_STATE__ ? cache.restore(window.__APOLLO_STATE__) : cache;

  const client = new ApolloClient({
    cache: hydratedCache,
    link,
    connectToDevTools: process.env.NODE_ENV !== 'production',
    typeDefs,
    resolvers,
  });

  return client;
}
